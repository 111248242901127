import React, { useEffect, useMemo, useRef, useState } from 'react';

import { z } from 'zod';
import { create } from 'zustand';
import { add } from 'date-fns';

import CookieService from 'app/utils/services/cookies';

import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Link, { type LinkProps } from '@mui/material/Link';
import { Portal } from '@mui/base/Portal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { TEST } from 'app/constants/env';
import Policy, { type PolicyProps } from './policy';
import classes from './styles.module.css';

type IPolicy = Pick<PolicyProps, 'id' | 'label' | 'description' | 'alwaysOn'>;

export interface CookieConfig {
  policies: IPolicy[];
  permissionLabels: {
    accept: string;
    acceptAll: string;
    decline: string;
  };
  header: {
    title: string;
    description: string;
  };
  cookiePreferenceKey: string;
  cookiePolicy: {
    url: string;
    label: string;
    props?: LinkProps;
  };
  customizeLabel: string;
}

export type PolicyConfiguration = Record<IPolicy['id'], number>;

interface CookieProps {
  config: CookieConfig;
  handlePolicies: (config: PolicyConfiguration) => void;
}

const createCookieContainer = () => {
  const cookieContainer = document.createElement('aside');
  cookieContainer.className = classes.cookieContainer;
  document.body.appendChild(cookieContainer);
  return cookieContainer;
};

const createValidationSchema = (policies: IPolicy[]) => {
  const schemaItems = policies.reduce<Record<IPolicy['id'], z.ZodNumber>>(
    (acc, policy) => ({
      ...acc,
      [policy.id]: z.number(),
    }),
    {},
  );

  return z.object(schemaItems);
};

interface CookieState {
  values: PolicyConfiguration;
  setValues: (values: PolicyConfiguration) => void;
  setValue: ({ key, value }: { key: keyof PolicyConfiguration; value: number }) => void;
}

export const useValues = create<CookieState>((set) => ({
  values: {} as PolicyConfiguration,
  setValues: (values) => set({ values }),
  setValue: ({ key, value }) => set((state) => ({ values: { ...state.values, [key]: value } })),
}));

const Cookies = ({ config, handlePolicies }: CookieProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const values = useValues((state) => state.values);
  const setValues = useValues((state) => state.setValues);
  const container = useRef<HTMLElement>();

  const validationSchema = useMemo(() => createValidationSchema(config.policies), [config.policies]);

  const openModal = () => {
    container.current = createCookieContainer();
    setIsOpen(true);
  };

  const defaultValues = useMemo(
    () =>
      config.policies.reduce<PolicyConfiguration>(
        (acc, policy) => ({
          ...acc,
          [policy.id]: Number(!!policy.alwaysOn),
        }),
        {},
      ),
    [config.policies],
  );

  useEffect(() => {
    try {
      const cookie = CookieService.get(config.cookiePreferenceKey, {
        parseJSON: true,
        validationSchema,
      });
      if (!cookie) {
        setValues(defaultValues);
        if (!TEST) {
          openModal();
        }
        return;
      }

      handlePolicies(cookie);
    } catch (error) {
      CookieService.remove(config.cookiePreferenceKey, { path: '/' });
      setValues(defaultValues);
      if (!TEST) {
        openModal();
      }
    }
  }, []);

  const saveValues = (val: PolicyConfiguration) => {
    handlePolicies(val);
    const url = new URL(window.location.href);
    const { hostname } = url;

    // to store across subdomains e.g .cleverkids.io cookie will be available on cleverkids.io, app.cleverkids.io, staging.cleverkids.io
    const domain = hostname.includes('cleverkids') ? `.${hostname.split('.').slice(-2).join('.')}` : hostname;

    CookieService.set(config.cookiePreferenceKey, val, {
      path: '/',
      expires: add(new Date(), { years: 1 }),
      domain,
    });
    setIsOpen(false);
  };

  const handleDecline = () => {
    saveValues(defaultValues);
  };

  const handleAccept = () => {
    saveValues(values);
  };

  const handleAcceptAll = () => {
    saveValues(
      Object.keys(values).reduce<PolicyConfiguration>(
        (acc, key) => ({
          ...acc,
          [key]: Number(true),
        }),
        {},
      ),
    );
  };

  return (
    <>
      {isOpen && (
        <Portal container={container.current}>
          <Paper elevation={2} className="rounded-xl p-6">
            <header className="mb-4">
              <Typography variant="h3" className="mb-1 text-2xl font-bold text-primary-main">
                {config.header.title}
              </Typography>
              <Typography className="text-black-900 text-sm font-semibold">{config.header.description}</Typography>
            </header>
            <section className="mb-6">
              <Accordion
                className="mb-0"
                elevation={0}
                classes={{
                  expanded: '!mb-4',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="p-0"
                  classes={{
                    content: 'mt-0',
                    root: 'min-h-[24px] items-start',
                    expandIconWrapper: 'pt-0 pb-0',
                  }}
                >
                  <Typography className="text-black-900 font-semibold">{config.customizeLabel}</Typography>
                </AccordionSummary>
                <AccordionDetails className="grid grid-flow-row gap-3 p-0">
                  {config.policies.map((policy) => (
                    <Policy
                      key={policy.id}
                      id={policy.id}
                      label={policy.label}
                      description={policy.description}
                      alwaysOn={policy.alwaysOn}
                      checked={!!values[policy.id]}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
              <div>
                <Link href={config.cookiePolicy.url} {...config.cookiePolicy.props} className="text-black underline">
                  {config.cookiePolicy.label}
                </Link>
              </div>
            </section>
            <footer>
              <div className="flex gap-4">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="rounded-[30px] border-primary-main px-5 py-2 text-xs uppercase text-primary-main"
                  onClick={handleDecline}
                >
                  {config.permissionLabels.decline}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  className="rounded-[30px] bg-primary-main px-5 py-2 text-xs uppercase text-white"
                  onClick={handleAccept}
                >
                  {config.permissionLabels.accept}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  className="rounded-[30px] bg-primary-main px-5 py-2 text-xs uppercase text-white"
                  onClick={handleAcceptAll}
                >
                  {config.permissionLabels.acceptAll}
                </Button>
              </div>
            </footer>
          </Paper>
        </Portal>
      )}
    </>
  );
};

export default Cookies;
