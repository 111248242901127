/* eslint-disable no-useless-escape */
/* eslint-disable func-names */
/* eslint-disable react/forbid-prop-types */
import * as Yup from 'yup';
import { ACTION_TYPE, SCHEDULE_TYPE } from 'data/enums/actions/action';
import { DIGITOOL_TYPE } from 'data/enums/digitool';
import { TEXT_QUESTION_TYPE } from 'data/enums/text-question';
import { type LinkShareEntity, type TodoEntity } from 'data/types/entities';
import { z, type ZodTypeAny } from 'zod';
import { type Language } from 'app/constants/data';
import { type FileRequest } from 'app/pages/tasks/new/types';
import { areWhatStepTasksEmpty, hasWhitespace, SANITIZERS } from './validation-helpers';

const supportedLanguages: Language[] = ['en', 'fr', 'nl'];
/**
 1) /^ and $/ indicate the beginning and end of the string, respectively.
 This means that the entire string must match the pattern, not just a portion of it.
 2) (?=[a-zA-Z0-9._]{4,40}$) is a positive lookahead assertion that ensures the string contains only alphanumeric characters,
 dots, or underscores, and is between 4 and 40 characters in length.
 3) (?!.*[_.]{2}) is a negative lookahead assertion that ensures the string does not contain two consecutive dots or underscores.
 4) [^_.] matches any character that is not a dot or underscore.
 5) * matches any number of characters, including zero.
 6) [^_.] matches any character that is not a dot or underscore again.
 Taken together, this regular expression ensures that the input string is between 4 and 40 characters in length
 and contains only alphanumeric characters, dots, or underscores.
 Additionally, it ensures that the string does not contain two consecutive dots or underscores,
 and starts and ends with a non-dot or underscore character.
 */
const usernameRegexp = /^(?=[a-zA-Z0-9._]{4,40}$)(?!.*[_.]{2})[^_.].*[^_.]$/;

Yup.addMethod(Yup.string, 'onlyCharacters', function (error) {
  return this.test('only characters', error, function (value) {
    const { createError } = this;
    const notValid = /[0-9-!$%^&*@()_+|~=`\\#{}\[\]:";'<>?,.\/]/.test(value || '');
    return !notValid || createError(error);
  });
});

export const AddActionSchema = Yup.object().shape(
  {
    allowSchedule: Yup.bool(),
    startsFrom: Yup.date().when('allowSchedule', {
      is: true,
      then: (schema) => schema.min(new Date(), 'pastDateDisabled').required('startsOnDateRequired'),
      otherwise: (schema) => schema,
    }),
    endsAt: Yup.date().when('duration', {
      is: (duration) => {
        if (duration === 'plan') return true;
        return false;
      },
      then: (schema) => schema.min(Yup.ref('startsFrom'), 'minDateRequired').required('required'),
      otherwise: (schema) => schema,
    }),
    duration: Yup.string(),
    name: Yup.string().required('taskNameRequired'),
    linkShares: Yup.array().when(['allowSchedule', 'todos', 'fileShares', 'fileRequests', 'questionBox', 'ids'], {
      is: (allowSchedule, todos, fileShares, fileRequests, questionBox, ids) =>
        !allowSchedule ||
        !!todos?.length ||
        !!fileShares?.length ||
        !!fileRequests?.length ||
        !!questionBox?.length ||
        !!ids?.length,
      then: (schema) =>
        schema.of(
          Yup.object().shape({
            url: Yup.string().url('invalidUrl').required('linkIsNotEmpty'),
          }),
        ),
      otherwise: (schema) =>
        schema.min(1, 'atLeastOneLink').of(
          Yup.object().shape({
            url: Yup.string().url('invalidUrl').required('linkIsNotEmpty'),
          }),
        ),
    }),
    todos: Yup.array().when(['allowSchedule', 'linkShares', 'fileShares', 'fileRequests', 'questionBox', 'ids'], {
      is: (allowSchedule, linkShares, fileShares, fileRequests, questionBox, ids) =>
        !allowSchedule ||
        !!linkShares?.length ||
        !!fileShares?.length ||
        !!fileRequests?.length ||
        !!questionBox?.length ||
        !!ids?.length,
      then: (schema) =>
        schema.of(
          Yup.object().shape({
            title: Yup.string().required('todoIsNotEmpty'),
          }),
        ),
      otherwise: (schema) =>
        schema.min(1, 'atLeastOneTodo').of(
          Yup.object().shape({
            title: Yup.string().required('todoIsNotEmpty'),
          }),
        ),
    }),
    fileShares: Yup.array().when(['allowSchedule', 'linkShares', 'todos', 'fileRequests', 'questionBox', 'ids'], {
      is: (allowSchedule, linkShares, todos, fileRequests, questionBox, ids) =>
        !allowSchedule ||
        !!linkShares?.length ||
        !!todos?.length ||
        !!fileRequests?.length ||
        !!questionBox?.length ||
        !!ids?.length,
      then: (schema) => schema,
      otherwise: (schema) => schema.min(1, 'atLeastOneFile'),
    }),
    fileRequests: Yup.array().when(['allowSchedule', 'linkShares', 'todos', 'fileShares', 'questionBox', 'ids'], {
      is: (allowSchedule, linkShares, todos, fileShares, questionBox, ids) =>
        !allowSchedule ||
        !!todos?.length ||
        !!fileShares?.length ||
        !!linkShares?.length ||
        !!questionBox?.length ||
        !!ids?.length,
      then: (schema) =>
        schema.of(
          Yup.object().shape({
            replaceName: Yup.string().required('replaceNameRequired'),
            suffixes: Yup.string().when('replaceName', {
              is: (replaceName) => !!replaceName,
              then: (schemaTwo) => schemaTwo.required('suffixesShouldntBeEmpty'),
              otherwise: (schemaTwo) => schemaTwo,
            }),
            folder: Yup.object().shape({
              name: Yup.string().required('folderIsRequired'),
            }),
            comment: Yup.string().required('instructionIsRequired'),
          }),
        ),
      otherwise: (schema) =>
        schema.min(1, 'atLeastOneRequest').of(
          Yup.object().shape({
            replaceName: Yup.string().required('replaceNameRequired'),
            suffixes: Yup.string().when('replaceName', {
              is: (replaceName) => !!replaceName,
              then: (schemaTwo) => schemaTwo.required('suffixesShouldntBeEmpty'),
              otherwise: (schemaTwo) => schemaTwo,
            }),
            folder: Yup.object().shape({
              name: Yup.string().required('folderIsRequired'),
            }),
            comment: Yup.string().required('instructionIsRequired'),
          }),
        ),
    }),
    questionBox: Yup.array().when(['allowSchedule', 'linkShares', 'todos', 'fileShares', 'fileRequests', 'ids'], {
      is: (allowSchedule, linkShares, todos, fileShares, fileRequests, ids) =>
        !allowSchedule ||
        !!todos?.length ||
        !!fileShares?.length ||
        !!linkShares?.length ||
        !!fileRequests?.length ||
        !!ids?.length,
      then: (schema) =>
        schema.of(
          Yup.object().shape({
            name: Yup.string().required('questionBoxNameRequired'),
            questionBoxTypes: Yup.array().min(1, 'questionBoxTypeRequired'),
          }),
        ),
      otherwise: (schema) =>
        schema.min(1, 'atLeastOneBox').of(
          Yup.object().shape({
            name: Yup.string().required('questionBoxNameRequired'),
            questionBoxTypes: Yup.array().min(1, 'questionBoxTypeRequired'),
          }),
        ),
    }),
    ids: Yup.array().when(['allowSchedule', 'linkShares', 'todos', 'fileShares', 'fileRequests', 'questionBox'], {
      is: (allowSchedule, linkShares, todos, fileShares, fileRequests, questionBox) =>
        !allowSchedule ||
        !!todos?.length ||
        !!fileShares?.length ||
        !!linkShares?.length ||
        !!fileRequests?.length ||
        !!questionBox?.length,
      then: (schema) => schema,
      otherwise: (schema) => schema.min(1, 'atLeastOneBox'),
    }),
    kidId: Yup.array().when(['groupId', 'allowSchedule'], {
      is: (groupId, allowSchedule) => !!groupId?.length || !allowSchedule,
      then: (schema) => schema,
      otherwise: (schema) => schema.min(1, 'oneKidRequired').typeError('oneKidRequired'),
    }),
    groupId: Yup.array().when(['kidId', 'allowSchedule'], {
      is: (kidId, allowSchedule) => !!kidId?.length || !allowSchedule,
      then: (schema) => schema,
      otherwise: (schema) => schema.min(1, 'oneGroupRequired').typeError('oneGroupRequired'),
    }),
  },
  [
    ['allowSchedule', 'linkShares'],
    ['allowSchedule', 'todos'],
    ['allowSchedule', 'fileShares'],
    ['allowSchedule', 'fileRequests'],
    ['allowSchedule', 'questionBox'],
    ['allowSchedule', 'ids'],
    ['kidId', 'groupId'],
    ['kidId', 'allowSchedule'],
    ['groupId', 'allowSchedule'],
    ['todos', 'linkShares'],
    ['todos', 'fileShares'],
    ['todos', 'fileRequests'],
    ['todos', 'questionBox'],
    ['linkShares', 'fileShares'],
    ['linkShares', 'fileRequests'],
    ['linkShares', 'questionBox'],
    ['fileShares', 'fileRequests'],
    ['fileShares', 'questionBox'],
    ['fileRequests', 'questionBox'],
    ['ids', 'fileShares'],
    ['ids', 'fileRequests'],
    ['ids', 'linkShares'],
    ['ids', 'todos'],
    ['ids', 'questionBox'],
  ],
);

export const EditActionSchema = Yup.object().shape(
  {
    allowSchedule: Yup.bool(),
    startsFrom: Yup.date().when('allowSchedule', {
      is: true,
      then: (schema) => schema.required('startsOnDateRequired').min(new Date(), 'pastDateDisabled'),
      otherwise: (schema) => schema.default(() => new Date()),
    }),
    endTime: Yup.date().when('duration', {
      is: (duration) => duration === 'plan',
      then: (schema) => schema.min(Yup.ref('startsFrom'), 'minDateRequired'),
      otherwise: (schema) => schema,
    }),
    startsFromPrev: Yup.bool(),
    duration: Yup.string().required('durationRequired'),
    name: Yup.string().required('taskNameRequired'),
    ids: Yup.array().when(
      ['startsFromPrev', 'allowSchedule', 'linkShares', 'todos', 'fileShares', 'fileRequests', 'questionBox'],
      {
        is: (startsFromPrev, allowSchedule, todos, fileShares, fileRequests, questionBox, linkShares) =>
          startsFromPrev
            ? !!todos?.length ||
              !!fileShares?.length ||
              !!fileRequests?.length ||
              !!questionBox?.length ||
              !!linkShares?.length
            : !allowSchedule ||
              !!todos?.length ||
              !!fileShares?.length ||
              !!fileRequests?.length ||
              !!questionBox?.length ||
              !!linkShares?.length,
        then: (schema) => schema,
        otherwise: (schema) => schema.min(1, 'atLeastOneBox'),
      },
    ),
    linkShares: Yup.array().when(
      ['startsFromPrev', 'allowSchedule', 'todos', 'fileShares', 'fileRequests', 'questionBox', 'ids'],
      {
        is: (startsFromPrev, allowSchedule, todos, fileShares, fileRequests, questionBox, ids) =>
          startsFromPrev
            ? !!todos?.length ||
              !!fileShares?.length ||
              !!fileRequests?.length ||
              !!questionBox?.length ||
              !!ids?.length
            : !allowSchedule ||
              !!todos?.length ||
              !!fileShares?.length ||
              !!fileRequests?.length ||
              !!questionBox?.length ||
              !!ids?.length,
        then: (schema) =>
          schema.of(
            Yup.object().shape({
              url: Yup.string().url('invalidUrl').required('linkIsNotEmpty'),
            }),
          ),
        otherwise: (schema) =>
          schema.min(1, 'atLeastOneLink').of(
            Yup.object().shape({
              url: Yup.string().url('invalidUrl').required('linkIsNotEmpty'),
            }),
          ),
      },
    ),
    todos: Yup.array().when(
      ['startsFromPrev', 'allowSchedule', 'linkShares', 'fileShares', 'fileRequests', 'questionBox', 'ids'],
      {
        is: (startsFromPrev, allowSchedule, linkShares, fileShares, fileRequests, questionBox, ids) =>
          startsFromPrev
            ? !!linkShares?.length ||
              !!fileShares?.length ||
              !!fileRequests?.length ||
              !!questionBox?.length ||
              !!ids?.length
            : !allowSchedule ||
              !!linkShares?.length ||
              !!fileShares?.length ||
              !!fileRequests?.length ||
              !!questionBox?.length ||
              !!ids?.length,
        then: (schema) =>
          schema.of(
            Yup.object().shape({
              title: Yup.string().required('todoIsNotEmpty'),
            }),
          ),
        otherwise: (schema) =>
          schema.min(1, 'atLeastOneTodo').of(
            Yup.object().shape({
              title: Yup.string().required('todoIsNotEmpty'),
            }),
          ),
      },
    ),
    fileShares: Yup.array().when(
      ['startsFromPrev', 'allowSchedule', 'linkShares', 'todos', 'fileRequests', 'questionBox', 'ids'],
      {
        is: (startsFromPrev, allowSchedule, linkShares, todos, fileRequests, questionBox, ids) =>
          startsFromPrev
            ? !!linkShares?.length ||
              !!todos?.length ||
              !!fileRequests?.length ||
              !!questionBox?.length ||
              !!ids?.length
            : !allowSchedule ||
              !!linkShares?.length ||
              !!todos?.length ||
              !!fileRequests?.length ||
              !!questionBox?.length ||
              !!ids?.length,
        then: (schema) => schema,
        otherwise: (schema) => schema.min(1, 'atLeastOneFile'),
      },
    ),
    fileRequests: Yup.array().when(
      ['startsFromPrev', 'allowSchedule', 'linkShares', 'todos', 'fileShares', 'questionBox', 'ids'],
      {
        is: (startsFromPrev, allowSchedule, linkShares, todos, fileShares, questionBox, ids) =>
          startsFromPrev
            ? !!linkShares?.length || !!todos?.length || !!fileShares?.length || !!questionBox?.length || !!ids?.length
            : !allowSchedule ||
              !!linkShares?.length ||
              !!todos?.length ||
              !!fileShares?.length ||
              !!questionBox?.length ||
              !!ids?.length,
        then: (schema) =>
          schema.of(
            Yup.object().shape({
              replaceName: Yup.string().required('replaceNameRequired'),
              suffixes: Yup.string().when('replaceName', {
                is: (replaceName) => !!replaceName,
                then: (schemaTwo) => schemaTwo.required('suffixesShouldntBeEmpty'),
                otherwise: (schemaTwo) => schemaTwo,
              }),
              folder: Yup.object().shape({
                name: Yup.string().required('folderIsRequired'),
              }),
              comment: Yup.string().required('instructionIsRequired'),
            }),
          ),
        otherwise: (schema) =>
          schema.min(1, 'atLeastOneRequest').of(
            Yup.object().shape({
              replaceName: Yup.string().required('replaceNameRequired'),
              suffixes: Yup.string().when('replaceName', {
                is: (replaceName) => !!replaceName,
                then: (schemaTwo) => schemaTwo.required('suffixesShouldntBeEmpty'),
                otherwise: (schemaTwo) => schemaTwo,
              }),
              folder: Yup.object().shape({
                name: Yup.string().required('folderIsRequired'),
              }),
              comment: Yup.string().required('instructionIsRequired'),
            }),
          ),
      },
    ),
    questionBox: Yup.array().when(
      ['startsFromPrev', 'allowSchedule', 'linkShares', 'todos', 'fileShares', 'fileRequests', 'ids'],
      {
        is: (startsFromPrev, allowSchedule, linkShares, todos, fileShares, fileRequests, ids) =>
          startsFromPrev
            ? !!linkShares?.length || !!todos?.length || !!fileShares?.length || !!fileRequests?.length || !!ids?.length
            : !allowSchedule ||
              !!linkShares?.length ||
              !!todos?.length ||
              !!fileShares?.length ||
              !!fileRequests?.length ||
              !!ids?.length,
        then: (schema) =>
          schema.of(
            Yup.object().shape({
              name: Yup.string().required('questionBoxNameRequired'),
              questionBoxTypes: Yup.array().min(1, 'questionBoxTypeRequired'),
            }),
          ),
        otherwise: (schema) =>
          schema.min(1, 'atLeastOneBox').of(
            Yup.object().shape({
              name: Yup.string().required('questionBoxNameRequired'),
              questionBoxTypes: Yup.array().min(1, 'questionBoxTypeRequired'),
            }),
          ),
      },
    ),
    kidId: Yup.array().when(['groupId', 'allowSchedule'], {
      is: (groupId, allowSchedule) => !!groupId?.length || !allowSchedule,
      then: (schema) => schema,
      otherwise: (schema) => schema.min(1, 'oneKidRequired').typeError('oneKidRequired'),
    }),
    groupId: Yup.array().when(['kidId', 'allowSchedule'], {
      is: (kidId, allowSchedule) => !!kidId?.length || !allowSchedule,
      then: (schema) => schema,
      otherwise: (schema) => schema.min(1, 'oneGroupRequired').typeError('oneGroupRequired'),
    }),
  },
  [
    ['allowSchedule', 'linkShares'],
    ['allowSchedule', 'todos'],
    ['allowSchedule', 'fileShares'],
    ['allowSchedule', 'fileRequests'],
    ['allowSchedule', 'questionBox'],
    ['kidId', 'groupId'],
    ['kidId', 'allowSchedule'],
    ['groupId', 'allowSchedule'],
    ['todos', 'linkShares'],
    ['todos', 'fileShares'],
    ['todos', 'fileRequests'],
    ['todos', 'questionBox'],
    ['linkShares', 'fileShares'],
    ['linkShares', 'fileRequests'],
    ['linkShares', 'questionBox'],
    ['fileShares', 'fileRequests'],
    ['fileShares', 'questionBox'],
    ['fileRequests', 'questionBox'],
    ['startsFromPrev', 'linkShares'],
    ['startsFromPrev', 'todos'],
    ['startsFromPrev', 'fileShares'],
    ['startsFromPrev', 'fileRequests'],
    ['startsFromPrev', 'questionBox'],
    ['startsFromPrev', 'allowSchedule'],
    ['ids', 'linkShares'],
    ['ids', 'todos'],
    ['ids', 'fileShares'],
    ['ids', 'fileRequests'],
    ['ids', 'questionBox'],
    ['ids', 'allowSchedule'],
    ['ids', 'startsFromPrev'],
  ],
);

export const SignupSchema = Yup.object().shape({
  user: Yup.object().shape({
    firstName: Yup.string().max(50, 'tooLong').required('firstNameRequired'),
    lastName: Yup.string().max(50, 'tooLong').required('lastNameRequired'),
    email: Yup.string()
      .email('invalidEmail')
      .test('has whitespace', 'passwordMustNotContainWhitespace', (val: string) => !hasWhitespace(val))
      .required('emailRequired'),
    password: Yup.string()
      .min(8, 'passwordMin8Char')
      .test('has whitespace', 'passwordMustNotContainWhitespace', (val: string) => !hasWhitespace(val))
      .required('passwordRequired'),
  }),
  hasAcceptedTerms: Yup.boolean().oneOf([true], 'termsRequired'),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('invalidEmail').required('emailRequired'),
});

export const ForgotSecretIdentifierSchema = Yup.object().shape({
  username: Yup.string().required('userNameRequired'),
});

export const SetKidPasswordSchema = Yup.object().shape({
  password: Yup.string().min(8, 'passwordMin8Char').required('passwordRequired'),
});

export const SetKidPasscodeSchema = Yup.object().shape({
  passcode: Yup.array().min(1).required('passcodeRequired'),
});

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string(),
  newPassword: Yup.string().min(8, 'passwordMin8Char').required('passwordRequired'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'passwordsMustMatch')
    .required('passwordRequired'),
});

export const AddVideoSchema = Yup.object().shape({
  name: Yup.string().required('videoNameRequired'),
  description: Yup.string(),
  link: Yup.string().required('videoLinkRequired'),
  type: Yup.string().required('videoTypeRequired'),
  gradesIds: Yup.array().min(0, 'oneGradeRequired'),
  domainsIds: Yup.array().min(0, 'oneDomainRequired'),
});

export const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().max(50, 'tooLong').required('firstNameRequired'),
  lastName: Yup.string().max(50, 'tooLong').required('lastNameRequired'),
  email: Yup.string().email('invalidEmail').required('emailRequired'),
  language: Yup.string()
    .oneOf(supportedLanguages, 'specifiedLanguageIsNotSupported')
    .required('defaultLanguageRequired'),
});

export const CreateKidSchema = Yup.object().shape({
  firstName: Yup.string().max(50, 'tooLong').required('firstNameRequired'),
  lastName: Yup.string().max(50, 'tooLong').required('lastNameRequired'),
  language: Yup.string()
    .oneOf(supportedLanguages, 'specifiedLanguageIsNotSupported')
    .required('defaultLanguageRequired'),
  defaultGradeId: Yup.string().required('defaultGradeRequired'),
  defaultCultureId: Yup.string().required('defaultCultureRequired'),
  password: Yup.string()
    .test('test password', 'patternOrPasswordRequired', function (val: string): boolean {
      const { passcode } = this.parent as { passcode: number[] };
      return !!passcode.length || !!val;
    })
    .test('has whitespace', 'passwordMustNotContainWhitespace', (val: string) => !hasWhitespace(val))
    .min(8, 'passwordMin8Char'),
  passcode: Yup.array().test('test passcode', 'patternOrPasswordRequired', function (val: number[]): boolean {
    const { password } = this.parent as { password: string };
    return !!password || !!val?.length;
  }),
});

export const EditKidSchema = Yup.object().shape({
  firstName: Yup.string().max(50, 'tooLong').required('firstNameRequired'),
  lastName: Yup.string().max(50, 'tooLong').required('lastNameRequired'),
  username: Yup.string().matches(usernameRegexp, 'invalidUserName').required('userNameRequired'),
  language: Yup.string()
    .oneOf(supportedLanguages, 'specifiedLanguageIsNotSupported')
    .required('defaultLanguageRequired'),
  defaultGradeId: Yup.string().required('defaultGradeRequired'),
});

export const KidSchema = Yup.object().shape({
  firstName: Yup.string().max(50, 'tooLong').required('firstNameRequired'),
  lastName: Yup.string().max(50, 'tooLong').required('lastNameRequired'),
  language: Yup.string()
    .oneOf(supportedLanguages, 'specifiedLanguageIsNotSupported')
    .required('defaultLanguageRequired'),
  defaultGradeId: Yup.string().required('defaultGradeRequired'),
  defaultCultureId: Yup.string().required('defaultCultureRequired'),
  email: Yup.string()
    .email('invalidEmail')
    .test('test email', 'emailOrGuardEmailRequired', function (val) {
      const { withEmail } = this.parent;
      return !withEmail || !!val;
    }),
  password: Yup.string()
    .test('test password', 'patternOrPasswordRequired', function (val) {
      /**
       * @type {Array<number>} passcode - pattern
       */
      const { withEmail, passcode } = this.parent;
      return withEmail || !!passcode.length || val;
    })
    .test('has whitespace', 'passwordMustNotContainWhitespace', (val: string) => !hasWhitespace(val))
    .min(8, 'passwordMin8Char'),
  passcode: Yup.string().test(
    'test passcode',
    'patternOrPasswordRequired',
    /**
     * @param {string} val - pattern
     * @return {boolean}
     */
    function (val) {
      const { withEmail, password } = this.parent;
      return withEmail || password || val;
    },
  ),
});

export const AddTeacherSchema = Yup.object().shape(
  {
    email: Yup.string().when('userId', {
      is: (userId) => !!userId?.length,
      then: (schema) => schema.email('invalidEmail'),
      otherwise: (schema) => schema.email('invalidEmail').required('emailRequired'),
    }),
    userId: Yup.array().when('email', {
      is: (email) => !!email?.length,
      then: (schema) => schema,
      otherwise: (schema) => schema.min(1, 'oneTeacherRequired').typeError('oneTeacherRequired'),
    }),
  },
  [['email', 'userId']],
);

export const CreateBookSchema = Yup.object().shape({
  title: Yup.string().required('titleRequired'),
  subtitle: Yup.string(),
  author: Yup.string().required('authorRequired'),
  serialNumber: Yup.string().required('serialNumberRequired'),
  description: Yup.string(),
  color: Yup.string(),
  public: Yup.bool().required(),
  versionDate: Yup.date().required('versionDateRequired'),
  pages: Yup.number().required('numberOfPagesRequired'),
  priority: Yup.number().required('priorityRequired'),
  files: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('nameRequired'),
    }),
  ),
});

export const GroupSubscriptionSchema = Yup.object().shape({
  numberOfLicenses: Yup.number().min(1).required('numberOfLicensesRequired'),
});

export const CreateBookLicenseSchema = Yup.object().shape(
  {
    name: Yup.string().required('nameRequired'),
    isPermanent: Yup.bool(),
    startsAt: Yup.date().required('startsOnDateRequired'),
    endsAt: Yup.mixed().test('test end date', 'endDateShouldBeLaterThanStartDate', function (val) {
      const { isPermanent, startsAt } = this.parent;
      if (isPermanent) return true;
      if (!val) return false;
      return startsAt < val;
    }),
    packageId: Yup.array().when('bookIds', {
      is: (bookIds) => !!bookIds?.length,
      then: (schema) => schema,
      otherwise: (schema) => schema.min(1, 'packageOrBooksRequired').typeError('packageOrBooksRequired'),
    }),
    bookIds: Yup.array().when('packageId', {
      is: (packageId) => !!packageId?.length,
      then: (schema) => schema,
      otherwise: (schema) => schema.min(1, 'packageOrBooksRequired').typeError('packageOrBooksRequired'),
    }),
    groupId: Yup.mixed().test('test group id', 'groupRequired', (val) => {
      if (Array.isArray(val)) {
        if (val.length === 0) return false;
      }
      return !!val;
    }),
  },
  [['packageId', 'bookIds']],
);

export const CreateFolderSchema = Yup.object().shape({
  name: Yup.string().required('folderNameRequired'),
});

export const QuestionBoxSchema = Yup.object().shape({
  name: Yup.string().required('questionBoxNameRequired'),
  questionBoxTypes: Yup.array().min(1, 'questionBoxTypeRequired'),
  languageId: Yup.string().required('languageRequired'),
});

export const ChangeKidPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .test('test password', 'patternOrPasswordRequired', function (val: string): boolean {
      const { passcode } = this.parent as { passcode: number[] };
      return !!passcode.length || !!val;
    })
    .test('has whitespace', 'passwordMustNotContainWhitespace', (val: string) => !hasWhitespace(val))
    .min(8, 'passwordMin8Char'),
  passcode: Yup.array().test(
    'test passcode',
    'patternOrPasswordRequired',
    /**
     * @param {Array<number>} val - pattern
     * @return {boolean}
     */
    function (val) {
      const { withEmail, newPassword } = this.parent;
      return withEmail || newPassword || !!val?.length;
    },
  ),
  confirmPassword: Yup.string().test('test confirmPassword', 'passwordsMustMatch', function (val) {
    /**
     * @type {Array<number>} passcode - pattern
     */
    const { newPassword, passcode } = this.parent;
    return !!passcode.length || (newPassword && newPassword === val);
  }),
});

const TodoSchema = Yup.object().shape({
  title: Yup.string().required('titleRequired'),
  comment: Yup.string().notRequired(),
});

const ShareFolderRequiredSchema = Yup.object().shape({
  id: Yup.string().required('folderIsRequired'),
  name: Yup.string(),
  path: Yup.string(),
});

const ShareFolderOptionalSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string(),
  path: Yup.string(),
});

const FileRequestSchema = Yup.object().shape({
  id: Yup.string(),
  replaceName: Yup.string(),
  comment: Yup.string(),
  allowReupload: Yup.boolean(),
  suffixes: Yup.string(),
  folder: ShareFolderRequiredSchema,
});

const LinkSchema = Yup.object().shape({
  url: Yup.string().required('urlRequired'),
  comment: Yup.string(),
});

export const WhatStepSchema = Yup.object().shape(
  {
    name: Yup.string().required('taskNameRequired'),
    questionBoxesIds: Yup.array().when(['fileShares', 'todos', 'linkShares', 'fileRequests'], {
      is: (
        fileShares?: File[],
        todos?: Pick<TodoEntity, 'title'>[],
        linkShares?: Omit<LinkShareEntity, 'id'>[],
        fileRequests?: FileRequest[],
      ) => areWhatStepTasksEmpty({ fileShares, todos, linkShares, fileRequests }),
      then: (schema) => schema.of(Yup.string()).min(1, 'questionBoxesRequired'),
      otherwise: (schema) => schema,
    }),
    questionBoxConfig: CreateBookLicenseSchema.when(['fileShares', 'todos', 'linkShares', 'fileRequests'], {
      is: (
        fileShares?: File[],
        todos?: Pick<TodoEntity, 'title'>[],
        linkShares?: Omit<LinkShareEntity, 'id'>[],
        fileRequests?: FileRequest[],
      ) => areWhatStepTasksEmpty({ fileShares, todos, linkShares, fileRequests }),
      then: (schema) => schema.required('questionBoxConfigRequired'),
      otherwise: (schema) => schema,
    }),
    todoComment: Yup.string().when(['fileShares', 'questionBoxesIds', 'linkShares', 'fileRequests', 'todos'], {
      is: (
        fileShares?: File[],
        questionBoxesIds?: string[],
        linkShares?: Omit<LinkShareEntity, 'id'>[],
        fileRequests?: FileRequest[],
        todos?: Pick<TodoEntity, 'title'>[],
      ) =>
        areWhatStepTasksEmpty({
          fileShares,
          questionBoxesIds,
          linkShares,
          fileRequests,
        }) || !!SANITIZERS.todos(todos || []).length,
      then: (schema) => schema.required('todoCommentRequired'),
      otherwise: (schema) => schema,
    }),
    todos: Yup.array().when(['fileShares', 'questionBoxesIds', 'linkShares', 'fileRequests'], {
      is: (
        fileShares?: File[],
        questionBoxesIds?: string[],
        linkShares?: Omit<LinkShareEntity, 'id'>[],
        fileRequests?: FileRequest[],
      ) => areWhatStepTasksEmpty({ fileShares, questionBoxesIds, linkShares, fileRequests }),
      then: (schema) => schema.of(TodoSchema).min(1, 'todosRequired').required('todosRequired'),
      otherwise: (schema) => schema,
    }),
    fileShares: Yup.array().when(['questionBoxesIds', 'todos', 'linkShares', 'fileRequests'], {
      is: (
        questionBoxesIds?: string[],
        todos?: Pick<TodoEntity, 'title'>[],
        linkShares?: Omit<LinkShareEntity, 'id'>[],
        fileRequests?: FileRequest[],
      ) => areWhatStepTasksEmpty({ questionBoxesIds, todos, linkShares, fileRequests }),
      then: (schema) => schema.min(1, 'atLeastOneFileRequired'),
      otherwise: (schema) => schema,
    }),
    fileShareComment: Yup.string().when(['fileShares'], {
      is: (fileShares?: File[]) => !!fileShares?.length,
      then: (schema) => schema.required('commentRequired'),
      otherwise: (schema) => schema,
    }),
    shareFolder: ShareFolderOptionalSchema.when(['fileShares'], {
      is: (fileShares?: File[]) => !!fileShares?.length,
      then: (schema) => schema.required('folderIsRequired'),
      otherwise: (schema) => schema,
    }),
    fileRequests: Yup.array().when(['fileShares', 'questionBoxesIds', 'todos', 'linkShares'], {
      is: (
        fileShares?: File[],
        questionBoxesIds?: string[],
        todos?: Pick<TodoEntity, 'title'>[],
        linkShares?: Omit<LinkShareEntity, 'id'>[],
      ) => areWhatStepTasksEmpty({ fileShares, questionBoxesIds, todos, linkShares }),
      then: (schema) => schema.of(FileRequestSchema).min(1, 'required').required('fileRequestsRequired'),
      otherwise: (schema) => schema,
    }),
    linkShares: Yup.array().when(['fileShares', 'questionBoxesIds', 'todos', 'fileRequests'], {
      is: (
        fileShares?: File[],
        questionBoxesIds?: string[],
        todos?: Pick<TodoEntity, 'title'>[],
        fileRequests?: FileRequest[],
      ) => areWhatStepTasksEmpty({ fileShares, questionBoxesIds, todos, fileRequests }),
      then: (schema) => schema.of(LinkSchema).min(1, 'required').required('linkSharesRequired'),
      otherwise: (schema) => schema,
    }),
  },
  [
    ['questionBoxesIds', 'fileShares'],
    ['questionBoxesIds', 'todos'],
    ['questionBoxesIds', 'linkShares'],
    ['questionBoxesIds', 'fileRequests'],
    ['questionBoxConfig', 'fileShares'],
    ['questionBoxConfig', 'todos'],
    ['questionBoxConfig', 'linkShares'],
    ['questionBoxConfig', 'fileRequests'],
    ['todoComment', 'fileShares'],
    ['todoComment', 'questionBoxesIds'],
    ['todoComment', 'linkShares'],
    ['todoComment', 'fileRequests'],
    ['todoComment', 'todos'],
    ['todos', 'fileShares'],
    ['todos', 'questionBoxesIds'],
    ['todos', 'linkShares'],
    ['todos', 'fileRequests'],
    ['fileShares', 'linkShares'],
    ['fileShares', 'fileRequests'],
    ['fileShareComment', 'fileShares'],
    ['shareFolder', 'fileShares'],
    ['shareFolder', 'fileRequests'],
    ['fileRequests', 'linkShares'],
  ],
);

export const WhoStepSchema = Yup.object()
  .shape(
    {
      selectedKids: Yup.array().when('selectedGroups', {
        is: (selectedGroups) => !selectedGroups?.length,
        then: (schema) => schema.min(1, 'oneKidRequired'),
        otherwise: (schema) => schema,
      }),
      selectedGroups: Yup.array().when('selectedKids', {
        is: (selectedKids) => !selectedKids?.length,
        then: (schema) => schema.min(1, 'oneGroupRequired'),
        otherwise: (schema) => schema,
      }),
    },
    [['selectedGroups', 'selectedKids']],
  )
  .required('required');

export const SendToStudentsStepSchema = Yup.object()
  .shape({
    type: Yup.mixed().oneOf([ACTION_TYPE.SIMPLE, ACTION_TYPE.QUICK], 'Please select an action type'),
  })
  .required('required');

export const WhenStepSchema = Yup.object()
  .shape(
    {
      scheduleType: Yup.mixed().oneOf([SCHEDULE_TYPE.DURATION, SCHEDULE_TYPE.PLAN]).required('required'),
      duration: Yup.string().when('scheduleType', {
        is: SCHEDULE_TYPE.DURATION,
        then: (schema) => schema.required('required'),
        otherwise: (schema) => schema,
      }),
      startsFrom: Yup.date().when('scheduleType', {
        is: SCHEDULE_TYPE.PLAN,
        /**
         * @description give 15 minutes buffer to the start date
         */
        then: (schema) =>
          schema.min(new Date(new Date().getTime() - 900_000), 'pastDateDisabled').required('startsOnDateRequired'),
        otherwise: (schema) => schema,
      }),
      endsAt: Yup.date().when('scheduleType', {
        is: SCHEDULE_TYPE.PLAN,
        then: (schema) => schema.min(Yup.ref('startsFrom'), 'minDateRequired').required('required'),
        otherwise: (schema) => schema,
      }),
    },
    [
      ['scheduleType', 'duration'],
      ['scheduleType', 'startsFrom'],
      ['scheduleType', 'endsAt'],
    ],
  )
  .required('required');

export const ShareQueestionBoxSchema = Yup.object().shape(
  {
    selectedUsers: Yup.array().when('selectedGroups', {
      is: (selectedGroups) => !!selectedGroups?.length,
      then: (schema) => schema,
      otherwise: (schema) => schema.min(1, 'oneTeacherRequired'),
    }),
    selectedGroups: Yup.array().when('selectedUsers', {
      is: (selectedUsers) => !!selectedUsers?.length,
      then: (schema) => schema,
      otherwise: (schema) => schema.min(1, 'oneGroupRequired'),
    }),
  },
  [['selectedUsers', 'selectedGroups']],
);

export const DuplicateQueestionBoxSchema = Yup.object().shape({
  questionBoxName: Yup.string().required('questionBoxNameRequired'),
});

export const OptionSchema = z.object({
  value: z.string(),
  label: z.string(),
});

export const SelectOptionSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type SelectOption = z.infer<typeof SelectOptionSchema>;

export const QuestionTypeSchema = SelectOptionSchema.extend({
  id: z.union([z.nativeEnum(DIGITOOL_TYPE), z.nativeEnum(TEXT_QUESTION_TYPE)]),
});

export const DigitoolSchema = SelectOptionSchema.extend({
  id: z.nativeEnum(DIGITOOL_TYPE),
});

export const ListEntitySchema = <T extends z.ZodType>(item: T) =>
  z.object({
    count: z.number(),
    items: z.array(item),
  });

/**
 * Converts "" to undefined for proper form validation.
 *
 * Form elements do not have null | undefined as their values, only "", which causes validation error in case of
 * z.string().email().optional() because "" doesn't go in the optional route and is validated as an email value.
 *
 * {@link https://github.com/colinhacks/zod/issues/1721}
 * @param type
 */
export function formValue<T extends ZodTypeAny>(type: T) {
  return z.union([z.literal('').transform(() => undefined), type]);
}

export function castToZodEnum<T extends object, V = T[keyof T]>(object: T) {
  return Object.values(object) as any as Readonly<[V, ...V[]]>;
}
